/* axios */
import { AxiosError } from 'axios'
/* services bases */
import { ApiService } from './constant.services'
/* models */
import {
    ComplementaryMoralRegister,
    ComplementaryRegister,
    ComplementaryRegisterResponse,
    Register,
    RegisterResponse,
    Verification,
} from '@models'
/* utils */
import { DateTime } from 'luxon'
import { onChangeFile } from '@utils'

export const registerServices = async (register: Register) => {
    try {
        const birthdateJSDate = register && new Date(register.birthdate)
        const birthdate = DateTime.fromJSDate(birthdateJSDate, { zone: 'UTC' })

        if (!register.firstname && !register.lastname && !register.prefix)
            return

        const lowerName = register && register.firstname.toLowerCase()

        const lowerLast = register && register.lastname.toLowerCase()

        const names: string[] = [],
            lastnames: string[] = []

        for (const i of lowerName.split(' ')) {
            names.push(i[0].toUpperCase() + i.substring(1))
        }

        for (const i of lowerLast.split(' ')) {
            lastnames.push(i[0].toUpperCase() + i.substring(1))
        }

        const firstname = names.join(' ')

        const lastname = lastnames.join(' ')

        const prefix = register.prefix.split('|')[1]

        // const firstname = lowerName.charAt(0).toUpperCase() + lowerName.slice(1)
        // const lastname = lowerLast.charAt(0).toUpperCase() + lowerLast.slice(1)

        const response = await ApiService.post<RegisterResponse>('user', {
            ...register,
            birthdate,
            firstname,
            lastname,
            prefix,
        })

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const complementaryRegisterServices = async (
    register: ComplementaryRegister
) => {
    try {
        const inside = register.inside == undefined ? ' ' : register.inside

        const address = `${register.street};${register.outside};${inside};${register.colony};${register.townHall};${register.state}`

        const information = {
            originCountry: register.originCountry,
            dni: register.dni.toUpperCase(),
            rfc: register.rfc == undefined ? '' : register.rfc.toUpperCase(),
            address: address,
            postalCode: register.postalCode,
            ocupation: register.ocupation,
            gender: register.gender,
            proofOfAddress: await onChangeFile(register.proofOfAddress),
        }

        const response = await ApiService.post<ComplementaryRegisterResponse>(
            'user/complementary-register/natural',
            information
        )

        if (response.data.error === true) return response.data

        return response.data.data
    } catch (error) {
        const err = error as AxiosError

        console.log(err)

        return err.response?.data
    }
}

export const complementaryMoralRegisterServices = async (
    register: ComplementaryMoralRegister
) => {
    try {
        console.log('register thunk', { register: register })

        const response = await ApiService.post<ComplementaryRegisterResponse>(
            'user/complementary-register/moral',
            register
        )

        if (response.data.error === true) return response.data

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const getVerificationEmail = async (email: string) => {
    try {
        const response = await ApiService.get<Verification>(
            `user/exist/email/${email}`
        )

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}

export const getVerificationPhone = async (phone: string) => {
    try {
        const response = await ApiService.get<Verification>(
            `user/exist/phone/${phone}`
        )

        return response.data
    } catch (error) {
        const err = error as AxiosError

        return err.response?.data
    }
}
