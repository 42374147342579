import { useEffect, FC, useState, Fragment } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { sha256 } from 'crypto-hash'

import AppRoutes from './App.routes'
import { AppLoader } from '@components'
import { useLoader } from '@hooks'

/* web3 connect */
/* web3 connect */
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { goerli, mainnet, sepolia } from 'wagmi/chains'
/* store */
import { RootState, authState } from './store'
import { useWebSocket } from './hooks'
import { useSelector } from 'react-redux'

const chains = [goerli, mainnet, sepolia]
const projectId = 'd66afe48a3bfb167db1d924bf32b169a'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)

async function getBrowserId() {
    let browserId = sessionStorage.getItem('bid')

    if (browserId != null) {
        return browserId
    }

    const userAgent = navigator.userAgent
    const language = navigator.language
    const screenResolution = `${screen.width}x${screen.height}`

    browserId = await sha256(
        `${uuidV4()}-${userAgent}-${language}-${screenResolution}`
    )

    sessionStorage.setItem('bid', browserId)

    return browserId
}

async function getXSPAT(browserId: string) {
    const xspat = process.env.REACT_APP_X_SPAT ?? ''

    return await sha256(`${browserId}|${xspat}`)
}

const App: FC = () => {
    // config app loader
    // const [loader, enableLoader, disableLoader] = useLoader(<AppLoader />)

    const [online, setOnline] = useState<boolean>(navigator.onLine)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const { status } = useSelector<RootState, authState>(state => state.auth)

    const [, createWebSocketConnection, , , closeConnection] = useWebSocket(
        process.env.REACT_APP_SOCKET!
    )

    const loadCSRF = async () => {
        try {
            const alreadyGetToken = false
            const browserId = await getBrowserId()
            const xspat = await getXSPAT(browserId)

            console.log('bid:', browserId)

            const response = await fetch(
                process.env.REACT_APP_URL + 'antiforgery/token',
                {
                    method: 'GET',
                    headers: {
                        'X-SPAT': xspat,
                        BID: browserId,
                    },
                }
            )

            if (response.ok) {
                const csfrToken = await response.text()
                sessionStorage.setItem('bcsfrt', csfrToken)
            }
        } catch (error) {
            console.error(error)
        } finally {
            const element = document.querySelector('#main-loader')

            if (element) {
                element.classList.add('hide')
            }

            setIsLoading(false)
        }
    }

    useEffect(() => {
        const handleOnline = () => setOnline(true)
        const handleOffline = () => setOnline(false)

        loadCSRF()

        window.addEventListener('online', handleOnline)
        window.addEventListener('offline', handleOffline)

        return () => {
            window.removeEventListener('online', handleOnline)
            window.removeEventListener('offline', handleOffline)
        }
    }, [])

    useEffect(() => {
        if (status == 'autheticated') {
            const token = sessionStorage.getItem('token')!

            createWebSocketConnection(token)
        } else if (status == 'not-autheticated') {
            closeConnection()
        }
        console.log('status', status)
    }, [status])

    return (
        <main className='App'>
            {!online ? (
                <p>Sin conexion</p>
            ) : isLoading ? (
                <div></div>
            ) : (
                <Fragment>
                    <WagmiConfig config={wagmiConfig}>
                        <AppRoutes />
                    </WagmiConfig>

                    <Web3Modal
                        projectId={projectId}
                        ethereumClient={ethereumClient}
                    />
                </Fragment>
            )}
        </main>
    )
}

export default App
